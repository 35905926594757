







import { Component, Prop, Vue } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import moment from 'moment'
import ICompanyResponse from '@/shared/modules/company/company-response.interface'
import Company from '@/shared/modules/company/company.model'

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class ChangeWorkHoursPerDayDialog extends Vue {
  @Prop() meta!: any
  form: FormBase = new FormBase()
    .setMethod(HttpMethod.PUT)
    .setEndpoint(this.meta.endpoint)
    .setModel(Company)
    .setFields([
      new Field()
        .setType(FieldTypes.datePicker)
        .setKey('date')
        .setTitle(__('admin.components.views.companies.change-work-hours-per-day-dialog.form.fields.date'))
        .setMeta({
          min: moment().add(1, 'day').format('YYYY-MM-DD'),
        }),
      new Field()
        .setType(FieldTypes.number)
        .setKey('amount')
        .setTitle(__('admin.components.views.companies.change-work-hours-per-day-dialog.form.fields.amount')),
    ])
    .setInitialValues(this.meta.changes && {
      date: this.meta.changes.date,
      amount: this.meta.changes.amount,
    })
    .setOnSuccess((response: ICompanyResponse, company: Company) => {
      this.$store.dispatch(GlobalActions.showSnackBar, {
        type: SnackBarTypes.success,
        message: __('admin.components.views.companies.change-work-hours-per-day-dialog.form.on-success'),
      })

      this.meta.onSuccess && this.meta.onSuccess(company)
    })
}
