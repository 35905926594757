


























import { Component, Prop, Vue } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import NumberField from '@/shared/components/form/field-types/NumberField.vue'
import FormField from '@/shared/components/form/FormField.vue'
import __ from '@/shared/helpers/__'
import { GlobalActions } from '@/shared/store/global/global.actions'
import ChangeWorkHoursPerDayDialog from '@/admin/components/views/companies/ChangeWorkHoursPerDayDialog.vue'
import { CompanyActions } from '@/shared/store/company/company.actions'
import Company from '@/shared/modules/company/company.model'

@Component({
  components: { FormField, NumberField },
  methods: { __ }
})
export default class ChangeWorkHoursPerDayField extends Vue {
  @Prop() form!: FormBase
  @Prop() meta!: any

  openDialog(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: ChangeWorkHoursPerDayDialog,
      meta: {
        company: this.form.entry,
        changes: this.meta.changes,
        endpoint: this.meta.endpoint,
        onSuccess: (company: Company) => {
          this.form.entry = company
          this.$store.dispatch(CompanyActions.setCompany, company)
          this.$store.dispatch(GlobalActions.closeDialog)
        }
      }
    })
  }
}
