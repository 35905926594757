








import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import { CompanyActions } from '@/shared/store/company/company.actions'
import ICompanyResponse from '@/shared/modules/company/company-response.interface'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import SelectItem from '@/shared/classes/form/fields/Select/select-item'
import ChangeWorkHoursPerDayField from '@/admin/components/views/companies/ChangeWorkHoursPerDayField.vue'

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class Info extends Vue {
  form: FormBase = new FormBase()
    .setEndpoint(`company/${ this.$store.getters[CompanyGetters.getCompany].slug }`)
    .setModel(Company)
    .setEntry(this.$store.getters[CompanyGetters.getCompany])
    .setFiles(true)
    .setFields([
      new Field()
        .setType(FieldTypes.file)
        .setKey('logo')
        .setTitle(__('company.views.settings.company.form.logo'))
        .setMeta({ type: 'image', accept: 'image/*' }),
      new Field()
        .setKey('name')
        .setTitle(__('company.views.settings.company.form.name')),
      new Field()
        .setType(FieldTypes.textArea)
        .setKey('description')
        .setTitle(__('company.views.settings.company.form.description')),
      new Field()
        .setKey('company_code')
        .setEntryKey('companyCode')
        .setTitle(__('company.views.settings.company.form.company-code'))
        .setSize(FieldSizes.half),
      new Field()
        .setKey('vat_code')
        .setEntryKey('vatCode')
        .setTitle(__('company.views.settings.company.form.vat-code'))
        .setSize(FieldSizes.half),
      new Field()
        .setKey('address')
        .setTitle(__('company.views.settings.company.form.address'))
        .setSize(FieldSizes.threeQuarters),
      new Field()
        .setKey('post_code')
        .setEntryKey('postCode')
        .setTitle(__('company.views.settings.company.form.post-code'))
        .setSize(FieldSizes.quarter),
      new Field()
        .setKey('city')
        .setTitle(__('company.views.settings.company.form.city'))
        .setSize(FieldSizes.half),
      new SearchableField()
        .setKey('country_uuid')
        .setEntryKey('countryUuid')
        .setTitle(__('company.views.settings.company.form.country'))
        .loadItems({ endpoint: '/countries', value: 'uuid', title: 'name', perPage: 20 })
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.custom)
        .setComponent(ChangeWorkHoursPerDayField)
        .setMeta((entry: Company) => ({
          label: __('admin.views.companies.form.work-hours-per-day'),
          value: entry.workHoursPerDay,
          changes: entry.workHoursPerDayChanges,
          endpoint: `company/${ this.company.slug }/change-work-hours-per-day`,
        }))
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.custom)
        .setComponent(ChangeWorkHoursPerDayField)
        .setMeta((entry: Company) => ({
          label: __('admin.views.companies.form.work-hours-per-day-on-weekend'),
          value: entry.workHoursPerDayOnWeekend,
          changes: entry.workHoursPerDayOnWeekendChanges,
          endpoint: `company/${ this.company.slug }/change-work-hours-per-day-on-weekend`,
        }))
        .setSize(FieldSizes.half),
      new Field()
        .setKey('website')
        .setTitle(__('company.views.settings.company.form.website'))
        .setSize(FieldSizes.half),
      new Field()
        .setKey('phone')
        .setTitle(__('company.views.settings.company.form.phone'))
        .setSize(FieldSizes.half),
      new SelectField()
        .setEntryKey('breakMinutes')
        .setKey('break_minutes')
        .setTitle(__('admin.views.companies.form.break'))
        .setItems([
          new SelectItem().setTitle('60 min.').setValue(60),
          new SelectItem().setTitle('30 min.').setValue(30),
          new SelectItem().setTitle('0 min.').setValue('0'),
        ])
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.number)
        .setKey('time_registration_availability')
        .setEntryKey('timeRegistrationAvailability')
        .setTitle(__('admin.views.companies.form.time-registration-availability'))
        .setSize(FieldSizes.half),
      new SearchableField()
        .setKey('warehouse_responsible_uuid')
        .setEntryKey('warehouseResponsibleUuid')
        .setTitle(__('admin.views.companies.form.warehouse-responsible'))
        .setVisibleIf(() => this.company.isToolsVisible)
        .loadItems({
          endpoint: `/company/${ this.company.slug }/employees`,
          value: 'uuid',
          title: 'full_name',
          perPage: 20
        }),
      new SearchableField()
        .setKey('hse_ceo_user_uuid')
        .setEntryKey('hseCeoUserUuid')
        .setTitle(__('admin.views.companies.form.hse-ceo'))
        .setVisibleIf(() => this.company.isHseVisible)
        .loadItems({
          endpoint: `/company/${ this.company.slug }/employees`,
          value: 'uuid',
          title: 'full_name',
          perPage: 20
        })
    ])
    .setOnSuccess(this.onSuccess)

  private onSuccess(data: ICompanyResponse, company: Company): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('company.views.settings.company.form.on-success'),
    })

    this.$store.dispatch(CompanyActions.setCompany, company)
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
